<template>
  <div>
    <b-container class="mt-5">
      <b-row>
        <b-col cols="6" md="2 mb-5">
          <h4 class="mt-1">Warenkorb</h4>
        </b-col>
        <b-col cols="3" md="2" offset-md="6">
          <router-link to="/"
            ><b-button variant="warning"
              ><font-awesome-icon
                icon="backward"
                class="mr-4"
                size="lg"
              />Zurück</b-button
            ></router-link
          ></b-col
        >
        <b-col cols="3" md="2">
          <b-button
            @click="goToPersonalInfos()"
            :disabled="cartItems.length == 0"
            variant="warning"
            ><font-awesome-icon
              icon="forward"
              class="mr-4"
              size="lg"
            />Weiter</b-button
          ></b-col
        >
      </b-row>
      <div class="cart">
        <div v-for="(cart, index) in sortedCartItems" :key="cart.index">
          <b-row>
            <b-col md="2" class="cart-list">
              {{ cart.quantity }} x
              <img
                v-if="node_env == 'production'"
                class="img-fluid w-25 mb-2"
                :src="cart.img"
                alt=""
              />
              <img
                v-else
                class="img-fluid w-25 mb-2"
                :src="api_url + cart.img"
                alt=""
              />
            </b-col>
            <b-col class="cart-items cart-list" md="8">
              <b>{{ cart.food }}</b>
              <i v-for="ingredient in cart.ingredients" :key="ingredient.index">
                {{ ingredient.name }}
                <span v-if="cart.ingredients.length > 1">,</span>
              </i>
              <i>{{ cart.message }}</i>
            </b-col>
            <b-col class="cart-items" md="1">
              <b>{{ cart.price }} CHF</b>
            </b-col>
            <b-col md="1">
              <div @click="deleteCartItem(index)" class="close">x</div>
            </b-col>
          </b-row>
          <hr />
        </div>
      </div>
      <b-row>
        <b-col md="12">
          <div v-if="cartItems.length != 0">
            <b> {{ totalPrice }} .-</b>
          </div>
          <div v-else>
            <b> Ihr Warenkorb ist leer!</b>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style lang="scss" scoped>
.close {
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .cart-items {
    margin-left: 25px;
  }
}
</style>

<script>
export default {
  name: "Cart",
  computed: {
    cartItems() {
      return this.$store.state.cartItems;
    },
    sortedCartItems() {
      return [...this.cartItems].sort((a, b) => {
        if (a.category < b.category) return -1;
        if (a.category > b.category) return 1;
        return 0;
      });
    },
    api_url() {
      return this.$store.state.api_url;
    },
    totalPrice() {
      let sum = 0;
      this.cartItems.forEach(function (cart) {
        sum += cart.price;
      });
      return sum;
    },
    node_env() {
      return this.$store.state.node_env;
    },
  },
  mounted() {
    this.$store.commit("RESET_SUCCESSFUL_ORDER_MSG");
  },
  methods: {
    deleteCartItem(index) {
      this.cartItems.splice(index, 1);
    },
    goToPersonalInfos() {
      this.$router.push("/personal-infos");
      this.updateOrderList();
    },
    updateOrderList() {
      this.$store.commit("RESET_ORDER_LIST");

      let orderlist = this.sortedCartItems
        .map((item) => {
          let ingredientsList = item.ingredients
            .map((ingredient) => ingredient.name)
            .join(", ");
          return (
            "+" +
            item.quantity +
            " " +
            item.food +
            " " +
            item.pizza_size +
            " " +
            "(" +
            item.price +
            " " +
            "chf" +
            ")" +
            "<br>" +
            ingredientsList +
            "<br>" +
            item.message
          );
        })
        .join("<br>");

      this.$store.commit("SET_ORDER_LIST", orderlist);
    },
  },
};
</script>
